import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Footer, Navbar, SocialIcons } from './components';
import { AnimatePresence } from 'framer-motion';

import {
  Home,
  About,
  Contact2,
  Gallery,
  Services,
  Testimonials,
  Error,
  Tints,
} from './Pages';
import ScrollToTop from './components/ScrollToTop';

function App() {
  let location = useLocation();
  return (
    <div className='app'>
      <div className='navEl'>
        <Navbar className='navbarEl' />
      </div>
      <SocialIcons />
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/tints' element={<Tints />} />
          <Route path='/services' element={<Services />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/contact' element={<Contact2 />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
