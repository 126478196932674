import React from 'react';
import './Error.scss';

function Error() {
  return (
    <div className='error-container'>
      <h1>ERROR 404 - Not Found</h1>
    </div>
  );
}

export default Error;
