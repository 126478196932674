import noise from '../assets/noise.gif';
import circle from '../assets/circle.svg';
// import logo from '../assets/logo.png';
import bd1 from '../assets/bd/bd-1.jpg';
import bd2 from '../assets/bd/bd-2.jpg';
import bd3 from '../assets/bd/bd-3.jpg';
import bd26 from '../assets/bd/bd-26.jpg';
import bd46 from '../assets/bd/bd-46.jpg';
import bd4 from '../assets/bd/bd-4.jpg';
import bd6 from '../assets/bd/bd-6.jpg';
import bd7 from '../assets/bd/bd-7.jpg';
import bd12 from '../assets/bd/bd-12.jpg';
import bd16 from '../assets/bd/bd-16.jpg';
import bd17 from '../assets/bd/bd-17.jpg';
import bd19 from '../assets/bd/bd-19.jpg';
import bd20 from '../assets/bd/bd-20.jpg';
import bd24 from '../assets/bd/bd-24.jpg';
import bd25 from '../assets/bd/bd-25.jpg';
import bd27 from '../assets/bd/bd-27.jpg';
import bd28 from '../assets/bd/bd-28.jpg';
import bd29 from '../assets/bd/bd-29.jpg';
import bd32 from '../assets/bd/bd-32.jpg';
import bd36 from '../assets/bd/bd-36.jpg';
import bd37 from '../assets/bd/bd-37.jpg';
import bd42 from '../assets/bd/bd-42.jpg';
import bd43 from '../assets/bd/bd-43.jpg';
import bd44 from '../assets/bd/bd-44.jpg';
import bd47 from '../assets/bd/bd-47.jpg';
import bd48 from '../assets/bd/bd-48.jpg';
import bdTint from '../assets/bd/bd-tint.jpg';
import tint_noFilm from '../assets/Tints/Car-Ceramic-No-Film.png';
import tint_five from '../assets/Tints/Car-Ceramic-5-Percent.png';
import quoteMarks from '../assets/quote-marks.svg';
import plusIcon from '../assets/plus-icon.png';
import plusIconTwo from '../assets/plus-icon2.png';
import processOne from '../assets/processIcons/wash1.svg';
import processTwo from '../assets/processIcons/wash2.svg';
import processThree from '../assets/processIcons/wash3.svg';
import processFour from '../assets/processIcons/wash4.svg';
import pin from '../assets/pin.png';
import mapPin from '../assets/mapPin.svg';
import logo from '../assets/logo_1.svg';
import mobileLogo from '../assets/logo_2.svg';
import logo2 from '../assets/logo_3.svg';
import dropletBg from '../assets/droplet1.png';
import dropletBg2 from '../assets/droplet2.png';
import dropletBg3 from '../assets/droplet3.png';
import threeEm_logo from '../assets/Partners/3m_logo.png';
import sparco_logo from '../assets/Partners/sparco_logo.png';
import igl_logo from '../assets/Partners/igl_logo.png';
import huper_logo from '../assets/Partners/huper_logo.png';

export default {
  circle,
  logo,
  noise,
  bd1,
  bd2,
  bd3,
  bd4,
  bd6,
  bd7,
  bd12,
  bd19,
  bd20,
  bd24,
  bd25,
  bd26,
  bd27,
  bd28,
  bd29,
  bd32,
  bd36,
  bd37,
  bd42,
  bd43,
  bd44,
  bd46,
  bd47,
  bd48,
  bdTint,
  tint_noFilm,
  tint_five,
  quoteMarks,
  plusIcon,
  plusIconTwo,
  processOne,
  processTwo,
  processThree,
  processFour,
  pin,
  mapPin,
  bd16,
  bd17,
  mobileLogo,
  logo2,
  dropletBg,
  dropletBg2,
  dropletBg3,
  threeEm_logo,
  sparco_logo,
  igl_logo,
  huper_logo,
};
